import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Anchor from '../../../components/ui/anchor'
import {ContactInfoWrapper, InfoBoxWrap, InfoBox} from './contact-info-area.style'

const ContactInfoArea = ({headingStyle, textStyle, phoneStyle}) => {
    const contactInfo = useStaticQuery(graphql `
        query {
            site {
                siteMetadata {
                    contact {
                        addressInfos {
                            id
                            state
                            office
                            address
                            email
                            phone
                        }
                    }
                }
            }
            file(relativePath: { eq: "simplr-map.png" }) {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
        }      
    `);
    const {addressInfos} = contactInfo.site.siteMetadata.contact;
    return (
        <ContactInfoWrapper id="section-contact">
            <Container>
                <Row>
                    {addressInfos && addressInfos.map(info => (
                        <Col lg={4} key={info.id}>
                            <InfoBoxWrap>
                                {info.state && <Heading {...headingStyle}>{info.state}</Heading>}
                                <InfoBox>
                                    {info.office && <Text {...textStyle}>{info.office}</Text>}
                                    {info.email && <Anchor {...textStyle} path={`mailto:${info.email}`}>{info.email}</Anchor>}
                                    {info.phone && <Text {...textStyle} {...phoneStyle}>{info.phone}</Text>}
                                </InfoBox>
                            </InfoBoxWrap>
                        </Col>
                        
                    ))}
                </Row>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.9026581452817!2d101.67512507455793!3d3.120444253329294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc498e7b3f64f5%3A0x54f04dce6ddf7aa2!2z6LC35Lit5Z-O!5e0!3m2!1szh-CN!2smy!4v1700541499540!5m2!1szh-CN!2smy" width="100%" height="450" frameborder="0"  style={{ border: 0, marginTop: 90 }}  allowfullscreen aria-hidden="false" tabindex="0"></iframe>
                {/* <Img style={{marginTop: 90}} fluid={contactInfo.file.childImageSharp.fluid} alt="A corgi smiling happily" /> */}
            </Container>
        </ContactInfoWrapper>
    )
}

ContactInfoArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    phoneStyle: PropTypes.object,
}

ContactInfoArea.defaultProps = {
    headingStyle: {
        as: "h5",
        mb: "20px"
    },
    textStyle: {
        mb: "7px"
    },
    phoneStyle: {
        fontweight: 800,
        color: 'headingColor'
    }
}

export default ContactInfoArea;