import styled from 'styled-components'
import {device} from '../../../theme'

export const PageHeaderWrap = styled.div `
    width: 100%;
    height: auto;
    background-image: url(${props => props.src});
    background-size:cover;
    background-position:center;
    padding-top: 192px;
    padding-bottom: 145px;
    @media ${device.large}{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media ${device.medium}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
`;